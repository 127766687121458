import SlideShowContainerComponent from '../components/SlideShowContainer/viewer/SlideShowContainer';
import SlideShowContainerController from '../components/SlideShowContainer/viewer/SlideShowContainer.controller';


const SlideShowContainer = {
  component: SlideShowContainerComponent,
  controller: SlideShowContainerController
};


export const components = {
  ['SlideShowContainer']: SlideShowContainer
};

